import { ArgumentType, ISetting } from '@biolibtech/biolib-js';
import { FormGroup, Intent } from '@blueprintjs/core';
import { Field, FieldProps } from 'formik';
import React from 'react';
import RenderGroupArguments from './RenderGroupArguments';
import RenderSetting from './RenderSetting';
import ErrorBoundary from '@components/ErrorBoundary';
import InputDescription from './InputDescription';

interface IProps {
    settings: ISetting[];
    autoSubmitOnceValid: boolean;
}

export default function RenderSettings(props: IProps) {
    const { settings } = props;
    return (
        <>
            {settings.map((setting) => {
                if (setting.render_type === ArgumentType.hidden) {
                    return null;
                }
                return (
                    <Field key={setting.public_id} name={setting.public_id}>
                        {(fieldProps: FieldProps) => {
                            const { meta, field, form } = fieldProps;
                            const { touched, error } = meta;
                            const subArgumentsToRender = setting.sub_arguments.filter(({ sub_argument_condition }) =>
                                sub_argument_condition === fieldProps.field.value
                            );
                            return (
                                <>
                                    <FormGroup
                                        helperText={touched && error && typeof error === 'string' ?
                                            <span className='break-all'>{error}</span> : ''
                                        }
                                        intent={touched && error ? Intent.DANGER : Intent.NONE}
                                        label={<InputDescription text={setting.description} />}
                                    >
                                        {setting.render_type === ArgumentType.group ?
                                            <RenderGroupArguments
                                                settings={setting.group_arguments}
                                                fieldProps={fieldProps}
                                            /> :
                                            <ErrorBoundary fallback={
                                                <p className='text-red-600'>
                                                    Failed to render input argument
                                                </p>
                                            }>
                                                <RenderSetting
                                                    setting={setting}
                                                    fieldProps={fieldProps}
                                                    autoSubmitOnceValid={props.autoSubmitOnceValid}
                                                />
                                            </ErrorBoundary>
                                        }
                                    </FormGroup>
                                    <RenderSettings
                                        settings={subArgumentsToRender}
                                        autoSubmitOnceValid={props.autoSubmitOnceValid}
                                    />
                                </>
                            );
                        }}
                    </Field>
                );
            })}
        </>
    );
}
