import { IAppVersionFull, IRemoteHost } from './AppTypes';
import { UserUuid } from './User.types';

interface IResult {
    created_at: string;
    name: string;
    ready_at: string;
    state: 'pending' | 'ready';
    uuid: string;
}

export type JobUuid = string;

interface ICloudJobNew {
    started_at: string | null;
    compute_node_url: string | null;
}

export interface IJob {
    uuid: string;
    app_uri: string;
    app_uuid: string;
    exit_code: null | number;
    state: JobState;
    auth_token: string;
    app_version: IAppVersionFull;
    caller_job: JobUuid | null;
    created_at: string;
    custom_compute_node_url?: string;
    public_id: JobUuid;
    remote_hosts_with_warning: IRemoteHost[];
    user_id: UserUuid;
    cloud_job: ICloudJobNew | null;
    ended_at: string | null;
    main_result: null | IResult;
    note_count: number;
}

export interface IJobPost {
    app?: string;
    app_resource_name_prefix?: string;
    app_version_id?: string;
    app_version_semantic?: string;
    caller_job?: string;
    client_type?: 'biolib-python' | 'biolib-frontend';
    client_version?: string;
}

export enum JobState {
    awaitingInput = 'awaiting_input',
    cancelled = 'cancelled',
    completed = 'completed',
    failed = 'failed',
    inProgress = 'in_progress',
}

export interface IJobPatch {
    state: JobState;
}

export type CloudJobUuid = string;

interface IComputeNodeInfo {
    url: string;
}

export interface ICloudJob {
    public_id: CloudJobUuid;
    is_compute_node_ready: boolean;
    compute_node_info: IComputeNodeInfo | null;
}

export interface ICloudJobFull extends ICloudJob {
    compute_node_info: IComputeNodeInfo;
}

export interface ICloudJobPost {
    job_id: JobUuid;
    module_name: string;
}

export interface IMultipartMetadata {
    ETag: string;
    PartNumber: number;
}
