import { Icon, IconName } from '@blueprintjs/core';
import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import SpinnerIcon from '@components/SpinnerIcon';

interface IProps {
    disableIsActive?: boolean;
    icon?: IconName;
    isLarge?: boolean;
    text: string;
    to?: string;
    useFullPath?: boolean;
    isLoading?: boolean;

    onClick?: () => void;
}

export default function MenuLink(props: IProps) {
    const className = clsx(
        'text-base text-current items-center hover:no-underline block rounded-md px-2 space-x-1.5',
        'hover:no-underline hover:bg-slate-100 w-full text-left',
        'disabled:cursor-wait',
        {
            'py-1 font-normal': !props.isLarge,
            'py-2 font-medium': props.isLarge,
            'bg-slate-50': !props.disableIsActive && typeof location !== 'undefined'
                && location.pathname === props.to,
        }
    );

    const linkContent: React.ReactNode = (
        <div className='inline-flex items-center justify-center space-x-2'>
            {props.isLoading ? <SpinnerIcon className='h-4 w-4'/> :
                <span className='text-slate-500'><Icon className='align-middle' icon={props.icon}/></span>
            }
            <span className='align-middle text-current'>{props.text}</span>
        </div>
    );

    if (props.to === undefined) {
        return (
            <button className={className} onClick={props.onClick} tabIndex={5} disabled={props.isLoading}>
                {linkContent}
            </button>
        );
    }

    if (props.useFullPath) {
        return (
            <a href={`https://biolib.com${props.to}`} className={className} onClick={props.onClick} tabIndex={5}>
                {linkContent}
            </a>
        );
    }

    return (<Link className={className} onClick={props.onClick} tabIndex={5} to={props.to}>{linkContent}</Link>);
};
