import { BioLibSingleton } from '../../../biolib-js/src';
import { buildCreateApi, coreModule, fetchBaseQuery, reactHooksModule } from '@reduxjs/toolkit/query/react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import config from '../config';

export enum ApiTagTypes {
    app = 'app',
    accountJobMetrics = 'accountJobMetrics',
    appSecret = 'appSecret',
    job = 'job',
    result = 'result',
    resultNotes = 'resultNotes',
}

export interface IPaginatedLegacy<T> {
    count: number;
    next: null | string;
    previous: null | string;
    results: T[];
}

export interface IPaginatedResponse<T> {
    object_count: number;
    page_count: number;
    results: T[];
}

export interface IPaginatedBaseQuery {
    page?: number;
    page_size?: number;
}

export interface IPaginatedResponse<T> {
    current_page_number: number;
    object_count: number;
    page_count: number;
    page_size: number;
    links: {
        next: null | string;
        previous: null | string;
    };
    results: T[];
}

// Using this "customCreateApi" magically fixes below build error:
// WebpackError: TypeError: Cannot destructure property 'store' of 'useReduxContext(...)' as it is null.
const customCreateApi = buildCreateApi(
    coreModule(),
    reactHooksModule({ useSelector, useDispatch, useStore }),
);

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = customCreateApi({
    tagTypes: Object.values(ApiTagTypes),
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/',
        prepareHeaders: async (headers, { getState }) => {
            try {
                // TODO: Eventually deprecate the custom BioLib httpClient
                const accessToken = await BioLibSingleton.get().httpClient.getAccessTokenAndFetchIfExpired();
                headers.set('authorization', `Bearer ${accessToken}`);
            } catch (error) {
                console.error('Failed to set authorization header got error: ', error);
            }
            headers.set('client-type', 'biolib-frontend');
            headers.set('client-version', config.frontendVersion);
            return headers;
        }
    }),
    endpoints: () => ({}),
});

export function rewriteUrlIfDev(url: string): string {
    if (config.isDev) {
        const urlObj = new URL(url);
        return `${location.origin}${urlObj.pathname}${urlObj.search}`;
    }
    return url;
}
