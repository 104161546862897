import { AppUuid, BioLibApiError, BioLibSingleton, IApp, ILicense, Paginated } from '@biolibtech/biolib-js';
import { navigate } from 'gatsby';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { EmptyPaginationState, IPaginationState } from '../../state';
import { accountsActions } from '../../state/accounts/accountsActions';
import { notificationActions } from '../../state/notification/notificationActions';
import { IRootState } from '../../state/rootReducer';
import { handleError } from '../../utils';
import appSettingsSlice, { IAppSettingsWrapper } from './appSettingsSlice';
import config from '../../config';
import { IGetAppResponse } from '@biolibtech/biolib-js/src/BioLib/resources/App';

function* watchOpenSettingsFromName() {
    yield takeLatest(appSettingsSlice.actions.openSettingsFromName.type, function* (action) {
        if (appSettingsSlice.actions.openSettingsFromName.match(action)) {
            try {
                const { accountHandle, appName } = action.payload;
                const uri = `${config.resourcePrefix}${accountHandle}/${appName}`;
                const { app }: IGetAppResponse = yield BioLibSingleton.get().app.fetchByUri(uri);

                const isSignedIn: boolean = yield select((state: IRootState) => state.user.isSignedIn);
                if (!isSignedIn) {
                    yield call(handleError, {
                        error: new Error('Not signed in'),
                        actionToDispatch: appSettingsSlice.actions.openSettingsFailure,
                        friendlyErrorMessageToPrepend: 'Unable to open settings',
                        showToast: true,
                    });
                    yield navigate(`/${config.resourcePrefix}${app.account_handle}/${app.name}/`);
                    return;
                }

                if (!app.is_editable_by_user) {
                    yield navigate(`${app.account_handle}/${app.name}/`);
                    const errorMessage = `You do not have editing permission for ${app.name}`;
                    yield put(appSettingsSlice.actions.openSettingsFailure(errorMessage));
                    yield put(notificationActions.addError(errorMessage));
                    return;
                }

                yield put(appSettingsSlice.actions.openSettingsSuccess({
                    appSettingsWrapper: { app, licenses: EmptyPaginationState }
                }))
                yield call(fetchLicenses, app.public_id);
            } catch (error) {
                yield call(handleError, {
                    error,
                    actionToDispatch: appSettingsSlice.actions.openSettingsFailure,
                    friendlyErrorMessageToPrepend: 'Unable to load settings',
                })
            }
        }
    })
}

function* watchOpenSettingsFromObject() {
    yield takeLatest(appSettingsSlice.actions.openSettingsFromObject.type, function* (action) {
        if (appSettingsSlice.actions.openSettingsFromObject.match(action)) {
            const { app } = action.payload;
            yield navigate(`/${config.resourcePrefix}${app.account_handle}/${app.name}/settings/`);
            yield put(appSettingsSlice.actions.openSettingsSuccess({
                appSettingsWrapper: { app, licenses: EmptyPaginationState }
            }))
            yield call(fetchLicenses, app.public_id);
        }
    })
}

function* watchUpdateAppMetadata() {
    yield takeLatest(appSettingsSlice.actions.updateAppMetadata.type, function* (action) {
        if (appSettingsSlice.actions.updateAppMetadata.match(action)) {
            try {
                const { appId, appMetadata, updateType } = action.payload;
                const app: IApp = yield BioLibSingleton.get().app.update(appId, appMetadata);
                yield put(appSettingsSlice.actions.updateAppMetadataSuccess({ app, updateType }));

                if ('name' in appMetadata) {
                    yield put(notificationActions.addSuccess(`Application renamed to:\n${appMetadata.name}`));
                }
                if ('name' in appMetadata || 'account_id' in appMetadata) {
                    yield navigate(`/${config.resourcePrefix}${app.account_handle}/${app.name}/settings/`);
                }
                const appFeedAccountHandles: string[] =
                    yield select((state: IRootState) => state.accounts.appFeedAccountHandles);
                yield put(accountsActions.appFeedFetch(appFeedAccountHandles));
            } catch (error) {
                let errorMessage = 'Failed to update application';
                if (error instanceof BioLibApiError) {
                    errorMessage = `${errorMessage}\n${error.toString()}`;
                } else {
                    errorMessage = `${errorMessage}\n${error.message}`;
                }
                yield put(appSettingsSlice.actions.updateAppMetadataFailure({
                    errorMessage,
                    updateType: action.payload.updateType,
                }));
                yield put(notificationActions.addError(errorMessage));
            }
        }
    })
}

function* watchDeleteApp() {
    yield takeLatest(appSettingsSlice.actions.deleteApp.type, function* (action) {
        if (appSettingsSlice.actions.deleteApp.match(action)) {
            try {
                const { appId } = action.payload;
                yield BioLibSingleton.get().app.deleteApp(appId);
                yield put(appSettingsSlice.actions.deleteAppSuccess());
                yield put(notificationActions.addSuccess(`Application deleted`));
                const appFeedAccountHandles: string[] =
                    yield select((state: IRootState) => state.accounts.appFeedAccountHandles);
                yield put(accountsActions.appFeedFetch(appFeedAccountHandles));
                yield navigate(`/`);
            } catch (error) {
                yield call(handleError, {
                    error,
                    actionToDispatch: appSettingsSlice.actions.deleteAppFailure,
                    friendlyErrorMessageToPrepend: 'Failed to delete application',
                    showToast: true,
                })
            }
        }
    })
}

function* watchCreateLicense() {
    yield takeLatest(appSettingsSlice.actions.createLicense.type, function* (action) {
        if (appSettingsSlice.actions.createLicense.match(action)) {
            try {
                const biolib = BioLibSingleton.get();
                const license: ILicense = yield biolib.license.create(action.payload.license);
                yield put(appSettingsSlice.actions.createLicenseSuccess({ license }));

                // TODO: Have appId be separate part of the action.payload
                const appId = action.payload.license.apps[0];
                yield call(fetchLicenses, appId);
                yield put(notificationActions.addSuccess('License created'));
            } catch (error) {
                yield call(handleError, {
                    error,
                    actionToDispatch: appSettingsSlice.actions.createLicenseFailure,
                    friendlyErrorMessageToPrepend: 'Failed to create license',
                    showToast: true,
                })
            }
        }
    })
}

function* watchUpdateLicense() {
    yield takeLatest(appSettingsSlice.actions.updateLicense, function* (action) {
        if (appSettingsSlice.actions.updateLicense.match(action)) {
            try {
                const { appId, licenseId, licenseUpdate } = action.payload;
                const biolib = BioLibSingleton.get();
                const license = yield biolib.license.update(licenseId, licenseUpdate);
                yield put(appSettingsSlice.actions.updateLicenseSuccess({ license }));
                yield call(fetchLicenses, appId);
                yield put(notificationActions.addSuccess('License updated'));
            } catch (error) {
                yield call(handleError, {
                    error,
                    actionToDispatch: appSettingsSlice.actions.updateLicenseFailure,
                    friendlyErrorMessageToPrepend: 'Failed to update license',
                    showToast: true,
                })
            }
        }
    })
}

function* fetchLicenses(app: AppUuid, page = 0, previousLicenseState?: IPaginationState<ILicense>) {
    try {
        const biolib = BioLibSingleton.get();

        const page_size = 10;
        let licenses: IPaginationState<ILicense>;

        if (previousLicenseState && page in previousLicenseState.pages) {
            licenses = {
                ...previousLicenseState,
                currentPage: page,
            };
        } else {
            const paginatedLicenses: Paginated<ILicense> = yield biolib.license.fetchCreatedForApp({
                app,
                page: page + 1,
                page_size,
            });

            licenses = {
                countPages: Math.ceil(paginatedLicenses.count / page_size),
                countPerPage: page_size,
                countTotal: paginatedLicenses.count,
                currentPage: page,
                pages: {
                    [page]: paginatedLicenses.results,
                }
            };

            if (previousLicenseState) {
                licenses.pages = {
                    ...previousLicenseState.pages,
                    ...licenses.pages,
                }
            }
        }

        yield put(appSettingsSlice.actions.setAppEditWrapperLicenses({ licenses }));
    } catch (error) {
        yield put(notificationActions.addError('Failed to fetch licenses for app'));
    }
}

function* watchLicensesChangePage() {
    yield takeLatest(appSettingsSlice.actions.licensesChangePage, function* (action) {
        if (appSettingsSlice.actions.licensesChangePage.match(action)) {
            const { newPageId } = action.payload;

            const appSettingsWrapper: IAppSettingsWrapper | null = yield select(
                (state: IRootState) => state.appSettings.appSettingsWrapper
            );

            if (!appSettingsWrapper) {
                return yield put(notificationActions.addError('Failed to change page'));
            }

            if (newPageId < 0 || newPageId >= appSettingsWrapper.licenses.countPages) {
                return yield put(notificationActions.addError('Invalid page change'));
            }

            yield call(fetchLicenses, appSettingsWrapper.app.public_id, newPageId, appSettingsWrapper.licenses);
        }
    })
}

export default function* appSettingsSaga() {
    yield fork(watchOpenSettingsFromName);
    yield fork(watchOpenSettingsFromObject);
    yield fork(watchDeleteApp);
    yield fork(watchCreateLicense);
    yield fork(watchLicensesChangePage);
    yield fork(watchUpdateAppMetadata);
    yield fork(watchUpdateLicense);
}
