import React, { ClipboardEvent, useEffect, useRef, useState } from 'react';
import '@blueprintjs/table/lib/css/table.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import { FieldProps } from 'formik';
import { HotkeysProvider } from '@blueprintjs/core';
import { parseFasta } from './validation';
import { DocumentIcon, TrashIcon } from '@heroicons/react/24/outline';
import { CellProps, Column, FocusedCellCoordinates, Region, Table2 } from '@blueprintjs/table';
import { getInitialRows, useScrollbarWidth } from './utils';
import TextAreaCell from './TextAreaCell';
import { resizeRowsByApproximateHeight } from '@blueprintjs/table/lib/esnext/resizeRows';
import ResizableDiv from './ResizeableDiv';
import PasteModal, { PasteType } from './PasteModal';
// import { BarsArrowDownIcon } from '@heroicons/react/20/solid';

const buttonStyle = 'w-full rounded bg-white py-1 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 flex justify-center disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none';

interface IProps extends FieldProps {

}

export default function SequenceInput(props: IProps) {
    const rows: Record<number, Record<number, string>> = props.field.value;

    const tableRef = useRef<Table2>();
    const containerRef = useRef<HTMLDivElement>();
    const fileInputRef = useRef<HTMLInputElement>();
    const [containerWidth, setContainerWidth] = useState(null);
    const [tableRenderInvalidator, setTableRenderInvalidator] = useState<boolean>(false);
    const [focusedCell, setFocusedCell] = useState<FocusedCellCoordinates>({ row: 0, col: 1, focusSelectionIndex: 0 });
    const [selectedRegions, setSelectedRegions] = useState<Region[]>([]);
    const scrollbarWidth = useScrollbarWidth() + 5;

    const columnWidths = [150, (containerWidth ?? 500) - 150 - scrollbarWidth];
    const [rowHeights, setRowHeights] = useState<number[]>([]);
    const [pastedTextForModal, setPastedTextForModal] = useState<string | null>(null);

    useEffect(() => {
        const rowHeights: number[] = resizeRowsByApproximateHeight(
            Object.keys(rows).length,
            columnWidths,
            (rowIndex: number, columnIndex: number) => rows[rowIndex][columnIndex] || '-',
            {
                getApproximateCharWidth: 7.5,
                getApproximateLineHeight: 20,
                getCellHorizontalPadding: 7,
                getNumBufferLines: 0,
            },
        );
        for (let i = 0; i < rowHeights.length; i += 1) {
            rowHeights[i] += 2; // add top and bottom padding;
        }
        setRowHeights(rowHeights);
        setTableRenderInvalidator(!tableRenderInvalidator);
    }, [containerWidth, rows]);

    const columns: string[] = [
        'Name',
        'Sequence',
    ];

    const updateRows = (rowsToUpdate: Record<number, Record<number, string>>, forceTableReRender: boolean) => {
        const newRows = { ...rows, ...rowsToUpdate };
        const newRowsLength = Object.keys(newRows).length;
        const lastRow = newRows[newRowsLength - 1];
        if (lastRow[0] || lastRow[1]) {
            // Last row not empty so add new empty row
            newRows[newRowsLength] = { 0: '', 1: '' };
        }
        props.form.setFieldValue(props.field.name, newRows, true);
        if (forceTableReRender) {
            setTableRenderInvalidator(!tableRenderInvalidator);
        }
    };

    useEffect(() => {
        const onResize = () => {
            if (containerRef.current) {
                setContainerWidth(Math.max(containerRef.current.offsetWidth, 100));
            }
        };
        onResize();
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    const cellRenderer = (rowIndex: number, columnIndex: number): React.ReactElement<CellProps> => (
        <TextAreaCell
            value={rows[rowIndex]?.[columnIndex] ?? ''}
            onFocus={() => {
                if (rowIndex === 0 && rowIndex === focusedCell.row && columnIndex === 1 && columnIndex === focusedCell.col) {
                    // initial focus do nothing
                } else {
                    props.form.setFieldTouched(props.field.name, true, true);
                }
            }}
            setValue={(newValue) => {
                updateRows({ [rowIndex]: { ...rows[rowIndex], [columnIndex]: newValue } }, false);
            }}
            placeholder={rowIndex !== 0 ? undefined : columnIndex === 0 ? `sequence_${rowIndex + 1}` : '[Write sequence or paste FASTA here]'}
        />
    );

    const handlePaste = (event: ClipboardEvent<HTMLDivElement>): void => {
        const rawText = event.clipboardData.getData('Text').trim();
        if (!focusedCell) {
            return;
        }

        if (rawText.startsWith('>') && rawText.includes('\n')) { // Parse FASTA
            const rowsToUpdate: Record<number, Record<number, string>> = {};
            try {
                const records = parseFasta(rawText);
                for (let i = 0; i < records.length; i += 1) {
                    const newRowIndex = focusedCell.row + i;
                    const record = records[i];
                    rowsToUpdate[newRowIndex] = { 0: record.sequenceId, 1: record.sequence };
                }
                event.preventDefault();
                updateRows(rowsToUpdate, true);
            } catch (error) {
                props.form.setFieldError(props.field.name, `Failed to parse pasted text as FASTA. Got error: ${error}`);
            }
        } else if (rawText.includes('\t')) { // Parse multiple columns from spreadsheet
            const pastedRows = rawText.split('\n');
            const rowsToUpdate: Record<number, Record<number, string>> = {};
            for (let i = 0; i < pastedRows.length; i += 1) {
                const pastedColumns = pastedRows[i].trim().split('\t');

                const newRowIndex = focusedCell.row + i;
                rowsToUpdate[newRowIndex] = newRowIndex in rows ? { ...rows[newRowIndex] } : {};
                if (focusedCell.col === 0) {
                    rowsToUpdate[newRowIndex][0] = (pastedColumns[0] ?? '').replace(/\s+/g, '');
                    if (pastedColumns.length > 1) {
                        rowsToUpdate[newRowIndex][1] = (pastedColumns[1] ?? '').replace(/\s+/g, '');
                    }
                } else if (focusedCell.col === 1) {
                    rowsToUpdate[newRowIndex][1] = (pastedColumns[0] ?? '').replace(/\s+/g, '');
                }
            }
            event.preventDefault();
            updateRows(rowsToUpdate, true);
        } else if (rawText.includes('\n')) { // Parse multiline text
            event.preventDefault();
            event.stopPropagation();
            setPastedTextForModal(rawText);
        }
    };

    const onFileSelect = async (files: FileList) => {
        if (!files || files.length === 0) {
            return;
        }
        const file = files[0];
        const text = await file.text();
        const rowCount = Object.keys(rows).length;
        let lastFilledRowIndex: number | null = null;
        for (let i = 0; i < rowCount; i += 1) {
            const row = rows[i];
            if (row[0] || row[1]) {
                lastFilledRowIndex = i;
            }
        }
        const rowIndexToInsert = lastFilledRowIndex === null ? 0 : lastFilledRowIndex + 1;
        const rowsToUpdate: Record<number, Record<number, string>> = {};
        try {
            const records = parseFasta(text);
            for (let i = 0; i < records.length; i += 1) {
                const newRowIndex = rowIndexToInsert + i;
                const record = records[i];
                rowsToUpdate[newRowIndex] = { 0: record.sequenceId, 1: record.sequence };
            }
            updateRows(rowsToUpdate, true);
            tableRef.current.scrollToRegion({ rows: [rowIndexToInsert, rowIndexToInsert] });
        } catch (error) {
            props.form.setFieldError(props.field.name, `Failed to parse file as FASTA. Got error: ${error}`);
        }
    };

    const deleteSelectedRegions = () => {
        const rowsToUpdate: Record<number, Record<number, string>> = {};
        for (const region of selectedRegions) {
            const { rows: [rowStart, rowEnd], cols: [colStart, colEnd] } = selectedRegions[0];
            for (let i = rowStart; i < rowEnd + 1; i += 1) {
                rowsToUpdate[i] = rows[i];
                for (let j = colStart; j < colEnd + 1; j += 1) {
                    rowsToUpdate[i][j] = '';
                }
            }
        }
        updateRows(rowsToUpdate, true);
    };

    return (
        <div className='flex flex-col md:flex-row w-full space-y-2 md:space-y-0 md:space-x-2'>
            <PasteModal
                isOpen={!!pastedTextForModal}
                columnName={focusedCell.col === 0 ? 'name' : 'sequence'}
                onClose={(pasteType) => {
                    if (pasteType === PasteType.multiple) {
                        const pastedRows = pastedTextForModal.split('\n');
                        const rowsToUpdate: Record<number, Record<number, string>> = {};
                        for (let i = 0; i < pastedRows.length; i += 1) {
                            const newRowIndex = focusedCell.row + i;
                            rowsToUpdate[newRowIndex] = newRowIndex in rows ? { ...rows[newRowIndex] } : {};
                            rowsToUpdate[newRowIndex][focusedCell.col] = pastedRows[i].replace(/\s+/g, '');
                        }
                        updateRows(rowsToUpdate, true);
                    } else if (pasteType === PasteType.single) {
                        const newRowIndex = focusedCell.row;
                        const rowsToUpdate: Record<number, Record<number, string>> = {};
                        rowsToUpdate[newRowIndex] = newRowIndex in rows ? { ...rows[newRowIndex] } : {};
                        rowsToUpdate[newRowIndex][focusedCell.col] = pastedTextForModal.replace(/\s+/g, '');
                        updateRows(rowsToUpdate, true);
                    }
                    setPastedTextForModal(null);
                }}
            />
            <div
                className='w-full rounded-md overflow-hidden border border-gray-300'
                onPasteCapture={handlePaste}
                ref={containerRef}
                onMouseEnter={() => document.documentElement.style.overscrollBehaviorX = 'none'}
                onMouseLeave={() => document.documentElement.style.overscrollBehaviorX = ''}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Backspace') {
                        // Add your backspace handling logic here
                        if (selectedRegions.length === 1) {
                            const { rows, cols } = selectedRegions[0];
                            const rowCount = rows[1] - rows[0];
                            const colCount = cols[1] - cols[0];
                            if (rowCount > 0 || colCount > 0) {
                                deleteSelectedRegions();
                            }
                        } else if (selectedRegions.length > 1) {
                            deleteSelectedRegions();
                        }

                    } else if (event.key === 'Delete') {
                        deleteSelectedRegions();
                    }
                }}
            >
                <ResizableDiv>
                    <HotkeysProvider>
                        <Table2
                            cellRendererDependencies={[tableRenderInvalidator]}
                            columnWidths={columnWidths}
                            enableColumnHeader={true}
                            enableColumnResizing={false}
                            enableFocusedCell={true}
                            enableRowHeader={false}
                            focusedCell={focusedCell}
                            getCellClipboardData={(row: number, col: number) => rows[row]?.[col] ?? ''}
                            key={!containerWidth ? 'loading' : 'loaded'}
                            numRows={rowHeights.length}
                            onFocusedCell={setFocusedCell}
                            onSelection={(selectedRegions) => setSelectedRegions(selectedRegions)}
                            ref={tableRef}
                            rowHeights={rowHeights}
                            selectedRegions={selectedRegions}
                        >
                            {columns.map((name, index) => (
                                <Column
                                    key={index}
                                    cellRenderer={cellRenderer}
                                    nameRenderer={() => (
                                        <span
                                            style={{ marginLeft: -2, fontSize: 12 }}
                                            className='text-gray-600 font-bold'
                                        >
                                            {name}
                                        </span>
                                    )}
                                />
                            ))}
                        </Table2>
                    </HotkeysProvider>
                </ResizableDiv>
            </div>
            <div className='flex-none md:w-40 lg:w-36'>
                <input
                    hidden={true}
                    multiple={false}
                    ref={fileInputRef}
                    type='file'
                    onChange={(event) => {
                        onFileSelect(event.target.files);
                        if (fileInputRef?.current) {
                            // Clear the hidden input field so next onChange will work for same file
                            fileInputRef.current.value = '';
                        }
                    }}
                />
                <div className='justify-between space-y-2'>
                    {/*
                    <button
                        className={buttonStyle}
                        onClick={() => console.log('Retrieve by ID')}
                    >
                        <BarsArrowDownIcon className='h-4 w-4 my-auto mr-1' aria-hidden='true' />
                        Retrieve by ID
                    </button>
*/}
                    <button
                        className={buttonStyle}
                        onClick={() => {
                            if (fileInputRef?.current) {
                                fileInputRef.current.click();
                            }
                        }}
                    >
                        <DocumentIcon className='h-4 w-4 my-auto mr-1' aria-hidden='true' />
                        Upload .fasta
                    </button>
                    <button
                        className={buttonStyle}
                        disabled={false}
                        onClick={() => {
                            props.form.setFieldTouched(props.field.name, false);
                            props.form.setFieldValue(props.field.name, getInitialRows(), true);
                            setTableRenderInvalidator(!tableRenderInvalidator);
                        }}
                    >
                        <TrashIcon className='h-4 w-4 my-auto mr-1' aria-hidden='true' />
                        Clear Table
                    </button>
                </div>
            </div>
        </div>
    );
}
