import {
    AppUuid,
    IApp,
    IAppMetadata,
    ILicense,
    ILicensePatch,
    ILicensePost,
    LicenseUuid,
} from '@biolibtech/biolib-js';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPaginationState } from '../../state';
import { ILoadingState, LoadingState } from '../../utils';

export interface IAppSettingsWrapper {
    app: IApp;
    licenses: IPaginationState<ILicense>;
}

export interface IAppSettingLoadingStates {
    clientSideExecutionChange: ILoadingState;
    nameChange: ILoadingState;
    ownerChange: ILoadingState;
    accessChange: ILoadingState;
    openSourceChange: ILoadingState;
    deleteApp: ILoadingState;
}

type AppMetadataUpdateType = keyof IAppSettingLoadingStates;

export interface IAppSettingsState {
    appSettingsWrapper: IAppSettingsWrapper | null;
    licenseToEdit: ILicense | null;

    settingsLoadingState: ILoadingState;
    appSettingLoadingStates: IAppSettingLoadingStates;
    createLicenseLoadingState: ILoadingState;
    licenseUpdateLoadingState: ILoadingState;
}

const initialState: IAppSettingsState = {
    appSettingsWrapper: null,
    licenseToEdit: null,
    appSettingLoadingStates: {
        clientSideExecutionChange: LoadingState.default,
        nameChange: LoadingState.default,
        ownerChange: LoadingState.default,
        accessChange: LoadingState.default,
        openSourceChange: LoadingState.default,
        deleteApp: LoadingState.default,
    },
    createLicenseLoadingState: LoadingState.default,
    settingsLoadingState: LoadingState.default,
    licenseUpdateLoadingState: LoadingState.default,
}

const appSettingsSlice = createSlice({
    name: 'appSettings',
    initialState,
    reducers: {
        openSettingsFromObject(state, action: PayloadAction<{ app: IApp; }>) {
            state.settingsLoadingState = LoadingState.begin;
        },

        openSettingsFromName(state, action: PayloadAction<{ accountHandle: string; appName: string; }>) {
            // no-op; Action is handled in saga
        },

        openSettingsSuccess(state, action: PayloadAction<{ appSettingsWrapper: IAppSettingsWrapper; }>) {
            state.settingsLoadingState = LoadingState.begin;
            state.appSettingsWrapper = action.payload.appSettingsWrapper;
        },

        openSettingsFailure(state, action: PayloadAction<string>) {
            state.settingsLoadingState = LoadingState.failure(action.payload);
        },

        updateAppMetadata(state, action: PayloadAction<{
            appId: AppUuid;
            appMetadata: Partial<IAppMetadata>;
            updateType: AppMetadataUpdateType;
        }>) {
            state.appSettingLoadingStates[action.payload.updateType] = LoadingState.begin;
        },

        updateAppMetadataSuccess(state, action: PayloadAction<{ app: IApp; updateType: AppMetadataUpdateType; }>) {
            state.appSettingLoadingStates[action.payload.updateType] = LoadingState.success;
            // Assume appEditWrapper is defined if this action is dispatched
            if (state.appSettingsWrapper) {
                state.appSettingsWrapper.app = action.payload.app;
            }
        },

        updateAppMetadataFailure(state, action: PayloadAction<{
            errorMessage: string;
            updateType: AppMetadataUpdateType;
        }>) {
            const { updateType, errorMessage } = action.payload;
            state.appSettingLoadingStates[updateType] = LoadingState.failure(errorMessage);
        },

        deleteApp(state, action: PayloadAction<{appId: string}>) {
            state.appSettingLoadingStates.deleteApp = LoadingState.begin;
        },

        deleteAppSuccess(state) {
            state.appSettingLoadingStates.deleteApp = LoadingState.success;
        },

        deleteAppFailure(state, action: PayloadAction<string>) {
            state.appSettingLoadingStates.deleteApp = LoadingState.failure(action.payload);
        },

        createLicense(state, action: PayloadAction<{ license: ILicensePost }>) {
            state.createLicenseLoadingState = LoadingState.begin;
        },

        createLicenseSuccess(state, action: PayloadAction<{ license: ILicense }>) {
            state.createLicenseLoadingState = LoadingState.success;
        },

        createLicenseFailure(state, action: PayloadAction<string>) {
            state.createLicenseLoadingState = LoadingState.failure(action.payload);
        },

        setLicenseToEdit(state, action: PayloadAction<{ license: ILicense | null; }>) {
            state.licenseToEdit = action.payload.license;
        },

        updateLicense(state, action: PayloadAction<{
            appId: AppUuid;
            licenseId: LicenseUuid;
            licenseUpdate: ILicensePatch;
        }>) {
            state.licenseUpdateLoadingState = LoadingState.begin;
        },

        updateLicenseSuccess(state, action: PayloadAction<{ license: ILicense }>) {
            state.licenseUpdateLoadingState = LoadingState.success;
            if (state.licenseToEdit && state.licenseToEdit.public_id === action.payload.license.public_id) {
                state.licenseToEdit = action.payload.license;
            }
        },

        updateLicenseFailure(state, action: PayloadAction<string>) {
            state.licenseUpdateLoadingState = LoadingState.failure(action.payload);
        },

        setAppEditWrapperLicenses(state, action: PayloadAction<{ licenses: IPaginationState<ILicense> }>) {
            // Assume appEditWrapper is defined if this action is dispatched
            if (state.appSettingsWrapper) {
                state.appSettingsWrapper.licenses = action.payload.licenses;
            }
        },

        licensesChangePage(state, action: PayloadAction<{ newPageId: number; }>) {
            // no-op here, handled in saga
        },

        clearState() {
            return initialState;
        },
    }
})

export default appSettingsSlice;
